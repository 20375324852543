<template>
  <div>
    <v-sheet class="header-backup" />
    <v-app-bar
      :color="$vuetify.theme.dark ? 'dark' : getThemeMode.appBarColor"
      :dark="getThemeMode.appBarColor != 'white' ? true : false"
      app
      class="px-sm text-left shadow-sm ma-4 rounded-lg"
      flat 
    >
      <v-app-bar-nav-icon
        v-ripple="{ class: 'primary--text' }"
        @click="toggleVerticalSidebarDrawer"
      />
      <vue-navigation-bar
        class="mt-0"
        :options="navbarOptions"
      />
      <v-progress-linear
        :active="getThemeMode.isLoading"
        :indeterminate="getThemeMode.isLoading"
        absolute
        bottom
        color="primary"
      />
      
      <v-spacer />
      <v-chip
        outlined
      >
        <span class="pr-2">UTC</span>
        <v-icon>mdi-clock</v-icon>
        <span class="pl-2">{{currentTime}}</span>
      </v-chip>

      <v-badge
        bordered
        overlap
        :content="latestActivityLogCount"
        :value="latestActivityLogCount"
        color="red"
        offset-x="22"
        offset-y="22"
      >
        <v-btn
          icon
          @click="notificationDrawer = !notificationDrawer"
        >
          <v-icon>mdi-bell</v-icon>
        </v-btn>
      </v-badge>

      <v-badge
        bordered
        overlap
        :content="criticalData.length"
        :value="criticalData.length"
        color="red"
        offset-x="22"
        offset-y="22"
      >
        <v-btn
          v-if="criticalData.length > 0"
          icon
          color="orange"
          @click="criticalDrawer = !criticalDrawer"
        >
          <v-icon>mdi-xml</v-icon>
        </v-btn>
      </v-badge>

      <v-btn
        icon
        style="display:none;"
        @click="searchDrawer = !searchDrawer"
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      
      <v-btn
        icon
        @click="changeTheme()"
      >        
        <v-icon v-if="this.$vuetify.theme.isDark">
          mdi-white-balance-sunny
        </v-icon>
        <v-icon v-if="!this.$vuetify.theme.isDark">
          mdi-weather-night
        </v-icon>
      </v-btn>      
      <v-chip
        pill
        class="transparent py-5"
        @click="userDrawer = !userDrawer"
      >
        Hi, {{ (loggedInUser) ? loggedInUser.first_name : 'Unauthorized' }}
        <v-avatar class="ml-2">
          <v-img src="@/assets/images/avatars/001-man.svg" />
        </v-avatar>
      </v-chip>
    </v-app-bar>

    <!-- userDrawer -->
    <v-navigation-drawer
      v-model="userDrawer"
      fixed
      right
      height="100%"
      temporary
      floating
      width="350"
    >
      <user-drawer>
        <template v-slot:userDrawerCloseButton>
          <v-btn
            icon
            color
            @click.stop="userDrawer = !userDrawer"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </user-drawer>

      <template v-slot:append>
        <div class="my-4 mx-4">
          <base-hover-button
            text="Logout"
            block
            bg-color="primary lighten-5 primary--text"
            icon-name="mdi-logout"
            @click.native="logoutUser"
          />
        </div>
      </template>
    </v-navigation-drawer>
    
    <!-- notificationDrawer  -->
    <v-navigation-drawer
      v-model="notificationDrawer"
      fixed
      right
      height="100%"
      temporary
      floating
      width="350" 
    >
      <notification-drawer>
        <template v-slot:notificationDrawerCloseButton>
          <v-btn
            icon
            color
            @click.stop="notificationDrawer = !notificationDrawer"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </notification-drawer>

      <template v-slot:append>
        <div class="my-4 mx-4">
          <router-link
            to="/app/admin/activity_logs"
          >
            <base-hover-button
              text="View All Activities"
              block
              bg-color="primary lighten-5 primary--text"
            />
          </router-link>
        </div>
      </template>
    </v-navigation-drawer>

    <!-- searchDrawer -->
    <v-navigation-drawer
      v-model="searchDrawer"
      fixed
      right
      height="100%"
      temporary
      floating
      width="380"
    >
      <search-drawer>
        <template v-slot:searchDrawerCloseButton>
          <v-btn
            icon
            color
            @click.stop="searchDrawer = !searchDrawer"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </search-drawer>
    </v-navigation-drawer>

    <!-- criticalDrawer  -->
    <v-navigation-drawer
      v-model="criticalDrawer"
      fixed
      right
      height="100%"
      temporary
      floating
      width="50%" 
    >
        <div class="pa-5 relative">
          <v-app-bar
            class="pt-1"
            fixed
            flat
          >
            <div class="d-flex justify-space-between align-center w-full">
              <h6 class="ma-0">
                Creative Content Munge
              </h6>
              <v-btn
                icon
                color
                @click.stop="criticalDrawer = !criticalDrawer"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </v-app-bar>
        </div>
        <v-list>
        <v-list-item
          v-for="item in criticalData"
          :key="item.title"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-file-xml-box</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.creative_name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="my-4 mx-4">
          <router-link
            to="/app/checker/creative-content-munge"
          >
            <base-hover-button
              text="View All"
              block
              bg-color="primary lighten-5 primary--text"
            />
          </router-link>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from 'moment';
import RestApi from "@/services/RestApi";

export default {
  name: "VerticallAppBar",
  mixins: [RestApi],
  components: {
    UserDrawer: () => import("../common-drawer/UserDrawer.vue"),
    NotificationDrawer: () => import("../common-drawer/NotificationDrawer.vue"),
    SearchDrawer: () => import("../common-drawer/SearchDrawer.vue")
  },
  data() {
    return {
      userDrawer: false,
      notificationDrawer: false,
      searchDrawer: false,      
      criticalDrawer: false,
      criticalData: [],
      currentTime: '',
      navbarOptions: {
        elementId: "main-navbar",
        isUsingVueRouter: true,
        mobileBreakpoint: 992,
        brandImagePath: "./",
        brandImageAltText: "brand-image",
        collapseButtonOpenColor: "#661c23",
        collapseButtonCloseColor: "#661c23",
        showBrandImageInMobilePopup: true,
        ariaLabelMainNav: "Main Navigation",
        tooltipAnimationType: "shift-away",
        menuOptionsLeft: [
        ]
      }
    };
  },
  computed: {
    ...mapGetters(["getThemeMode", "loggedInUser", "latestActivityLogCount"]),
  },
  async mounted() {
    const _this = this;
    setInterval(function () {
      _this.currentTime = moment(new Date()).utc().format('MM-DD-YYYY HH:mm:ss');
    }, 1000);

    window.refreshCritical = async () => {
      _this.criticalData = await _this.getList(
        ["*"],
        "vw_creative_munge_checker",
        [],
        [],
        "1"
      );
    } 

    await window.refreshCritical();
  },
  methods: {
    ...mapActions([
      "changeVerticalSidebarDrawer",
      "changeVerticalSidebarMini",
      "signOut",
    ]),
    toggleVerticalSidebarDrawer() {
      this.changeVerticalSidebarDrawer();

      // this.$emit("update:mini-variant");
      // console.log("check");
    },
    changeTheme() {
      this.$vuetify.theme.isDark = !this.$vuetify.theme.isDark
    },
    logoutUser() {
      this.signOut();

      this.$router.push("/app/sessions/login");
    },    
  }
};
</script>

<style lang="scss">
.header-backup {
  display: block;
  width: 100%;
  height: 102px;
  position: fixed;
  top: 0;
  z-index: 5;
  background: linear-gradient(
    180deg,
    hsla(0, 0%, 97.3%, 0.95) 44%,
    hsla(0, 0%, 97.3%, 0.46) 73%,
    hsla(0, 0%, 100%, 0)
  ) !important;
  left: 0;
  &.theme--dark {
    background: linear-gradient(
      180deg,
      rgba(5, 5, 5, 0.95) 44%,
      rgba(0, 0, 0, 0.46) 3%,
      hsla(0, 0%, 100%, 0)
    ) !important;
  }
}
.tippy-box[data-theme~="light"] {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.vnb {
  background: transparent !important;
  &__menu-options {
    margin-top: 3px !important;
    &__option {
      &__link {
        &:focus {
          outline: none;
          border: 1px solid none;
        }
        &:hover {
          color: #0081ff;
          .vnb__menu-options__option__arrow {
            fill: #0081ff;
          }
        }

        &__icon {
          svg {
            fill: #0081ff !important;
          }
        }
      }
    }
  }

  &__sub-menu-options {
    &__option {
      &__link {
        &:focus {
          outline: none;
          border: 1px solid none;
        }
        color: #000 !important;
        &:hover {
          color: #0081ff !important;
        }
      }
    }
  }
}

.vnb__collapse-button {
  &:focus {
    border: 1px solid none;
    outline: none;
  }
  &:after {
    content: "\F035C";
    font-size: 25px;
    font-weight: 600;
    font-family: "Material Design Icons";
  }
  svg {
    display: none !important;
  }
}

.vnb__popup {
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: scroll !important;
  .vnb__popup__top__close-button {
    &:focus {
      border: 1px solid none;
      outline: none;
    }
    top: 20px;
    right: -33px;
    svg {
      fill: #000 !important;
    }
  }
}
.v-navigation-drawer {
  z-index: 999999 !important;
}
</style>
